@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-50: #eefbf5;
    --primary-200: #b1e9cf;
    --primary-400: #49bf94;
    --primary-500: #25a47a;
    --primary-600: #178462;
    --primary-700: #126a51;
    --primary-800: #115441;
    --background-color: #eefbf5; /* Default secondary color */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --success: 123 71% 91%; /* Light green background */
    --success-foreground: 222.2 47.4% 11.2%; /* Green text */
    /* --primary: 222.2 47.4% 11.2%; */
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --error: 0 100% 95%;
    --error-foreground: 0 100% 48%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: var(--background-color);
  color: var(--primary-color); */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@layer base {
    :root {
        --sidebar-background: 0 0% 98%;
        --sidebar-foreground: 240 5.3% 26.1%;
        --sidebar-primary: 240 5.9% 10%;
        --sidebar-primary-foreground: 0 0% 98%;
        --sidebar-accent: 240 4.8% 95.9%;
        --sidebar-accent-foreground: 240 5.9% 10%;
        --sidebar-border: 220 13% 91%;
        --sidebar-ring: 217.2 91.2% 59.8%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

/* @layer components {
  .border-border {
    border-radius: var(--radius);
  }
} */

@keyframes blink {
    0%,
    100% {
        opacity: 1;
        box-shadow: 0 0 8px 4px rgba(34, 197, 94, 0.6); /* Green shadow */
    }
    50% {
        opacity: 0.6;
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.3); /* Shadow disappears */
    }
}

.shadow-green {
    box-shadow: 0 0 1px 1px rgba(34, 197, 94, 0.6); /* Green shadow */
}

.animate-blink {
    animation: blink 1s infinite;
}

.label-3kk12 {
    color: white !important;
}
